<template>
  <div id="page-view">
    <!-- <div class="breadcrumbs">
      <div class="container">
        <ul class="items">
          <li class="item home">
            <router-link to="/" title="Go to Home Page">
              Home </router-link>
          </li>
          <li class="item category112">
            <strong>About Us</strong>
          </li>
        </ul>
      </div>
    </div> -->
    <section class="bg-light  ">
      <main>
        <div class="container py-5">
          <!-- <div v-html="description" ></div> -->
          <div class="container py-5">
            <div>
              <div class="page-title">
                <h2>PRIVACY POLICY</h2>
              </div>
              <div class="static-contain text-justify">
                <p>
                  <span style="color: #808080">GOVAVA ("us", "we", or "our") operates the&nbsp;<a class="vglnk"
                      style="color: #808080" href="http://www.govava.com/" rel="nofollow">www.govava.com</a>
                    website and the govava Mobile App (the "Service").</span><br /><br /><span style="color: #808080">This
                    page informs you of our policies regarding the collection, use,
                    and disclosure of personal data when you use our Service and the
                    choices you have associated with that data.</span><br /><br /><span style="color: #808080">We use your
                    data to provide and improve the Service. By using the
                    Service, you agree to the collection and use of information in
                    accordance with this policy. Unless otherwise defined in this Privacy
                    Policy, the terms used in this Privacy Policy have the same meanings
                    as in our Terms and Conditions.</span>
                </p>
                <p>
                  <span style="color: #808080"><strong>Information Collection And Use</strong></span><br /><span
                    style="color: #808080">We collect several different types of information for various
                    purposes to provide and improve our Service to you.</span><br /><span
                    style="color: #808080"><strong>Types of Data Collected</strong></span><br /><span
                    style="color: #808080"><em><strong>Personal Data</strong></em></span><br /><span
                    style="color: #808080">While using our Service, we may ask you to provide us with certain
                    personally identifiable information that can be used to contact or
                    identify you ("Personal Data"). Personally, identifiable information
                    may include, but is not limited to:</span>
                </p>
                <p><br /><span style="color: #808080">Email address</span></p>
                <p><span style="color: #808080">First name and last name</span></p>
                <p><span style="color: #808080">Phone number</span></p>
                <p>&nbsp;</p>
                <p><span style="color: #808080">Cookies and Usage Data</span></p>
                <p>
                  <em style="color: #808080"><strong>Usage Data</strong></em>
                </p>
              </div>
              <div class="static-contain text-justify">
                <p>
                  <span style="color: #808080">We may also collect information that your browser sends whenever you
                    visit our Service or when you access the Service by or through a
                    mobile device ("Usage Data"). This Usage Data may include information
                    such as your computer's Internet Protocol address (e.g. IP address),
                    browser type, browser version, the pages of our Service that you
                    visit, the time and date of your visit, the time spent on those pages,
                    unique device identifiers and other diagnostic data</span>
                </p>
                <p>
                  <span style="color: #808080">When you access the Service by or through a mobile device, this Usage
                    Data may include information such as the type of mobile device you
                    use, your mobile device unique ID, the IP address of your mobile
                    device, your mobile operating system, the type of mobile Internet
                    browser you use, unique device identifiers and other diagnostic
                    data.</span><br /><br /><span style="color: #808080"><em><strong>Tracking &amp; Cookies
                        Data</strong></em></span><br /><span style="color: #808080">We use cookies and similar tracking
                    technologies to track the
                    activity on our Service and hold certain information.&nbsp; Cookies
                    are files with a small amount of data that may include an anonymous
                    unique identifier. Cookies are sent to your browser from a website and
                    stored on your device. Tracking technologies also used are beacons,
                    tags, and scripts to collect and track information and to improve and
                    analyze our Service</span>
                </p>
                <p>
                  <span style="color: #808080">You can instruct your browser to refuse all cookies or to indicate
                    when a cookie is being sent. However, if you do not accept cookies,
                    you may not be able to use some portions of our Service.</span><br /><span
                    style="color: #808080">Examples of Cookies we use:</span>
                </p>
                <p>
                  <span style="color: #808080">&nbsp;We use Session Cookies to operate our Service.</span><br /><span
                    style="color: #808080">&nbsp;We use Preference Cookies to remember your preferences and
                    various settings.</span><br /><span style="color: #808080">&nbsp;We use Security Cookies for security
                    purposes.</span><br /><br /><span style="color: #808080"><em><strong>Use of
                        Data</strong></em></span><br /><span style="color: #808080">&nbsp;&nbsp; &nbsp;</span><br /><span
                    style="color: #808080">GOVAVA uses the collected data for various purposes:&nbsp;&nbsp;
                    &nbsp;</span>
                </p>
                <p style="text-align: left">
                  <span style="color: #808080">&nbsp; To provide and maintain the Service</span><br /><span
                    style="color: #808080">&nbsp; To notify you about changes to our Service</span><br /><span
                    style="color: #808080">&nbsp; To allow you to participate in interactive features of our
                    Service when you choose to do so</span><br /><span style="color: #808080">&nbsp; To provide customer
                    care and support</span><br /><span style="color: #808080">&nbsp; To provide analysis or valuable
                    information so that we can
                    improve the Service</span><br /><span style="color: #808080">&nbsp; To monitor the usage of the
                    Service</span><br /><span style="color: #808080">&nbsp; To detect, prevent and address technical
                    issues</span>
                </p>
                <p style="text-align: left">
                  <span style="color: #808080"><em><strong>Transfer Of Data</strong></em></span><br /><span
                    style="color: #808080">Your information, including Personal Data, may be transferred to —
                    and maintained on — computers located outside of your state, province,
                    country, or other governmental jurisdiction where the data protection
                    laws may differ from those from your jurisdiction&nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp;
                  </span>
                </p>
                <p style="text-align: left">
                  <span style="color: #808080">If you are located outside the United States and choose to provide
                    information to us, please note that we transfer the data, including
                    Personal Data, to the United States and process it there.</span><br /><span
                    style="color: #808080">Your consent to this Privacy Policy followed by your submission of
                    such information represents your agreement to that transfer&nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  </span>
                </p>
                <p style="text-align: left">
                  <span style="color: #808080">GOVAVA will take all steps reasonably necessary to ensure that your
                    data is treated securely and in accordance with this Privacy Policy
                    and no transfer of your Personal Data will take place to an
                    organization or a country unless there are adequate controls in place
                    including the security of your data and other personal
                    information.</span><br /><br /><span style="color: #808080"><strong>Disclosure Of
                      Data</strong></span><br /><br /><span style="color: #808080"><strong>Legal
                      Requirements</strong></span><br /><span style="color: #808080">GOVAVA may disclose your Personal
                    Data in the good faith belief that
                    such action is necessary to:</span><br /><span style="color: #808080">&nbsp; </span>
                </p>
                <p style="text-align: left">
                  <span style="color: #808080">To comply with a legal obligation</span><br /><span
                    style="color: #808080">To protect and defend the rights or property of&nbsp; GOVAVA</span><br /><span
                    style="color: #808080">To prevent or investigate possible wrongdoing in connection with the
                    Service</span><br /><span style="color: #808080">To protect the personal safety of users of the
                    Service or the
                    public</span><br /><span style="color: #808080">To protect against legal
                    liability</span><br /><br /><span style="color: #808080"><em><strong>Security Of
                        Data</strong></em></span><br /><span style="color: #808080">The security of your data is important
                    to us, but remember that no
                    method of transmission over the Internet, or method of electronic
                    storage is 100% secure. While we strive to use commercially acceptable
                    means to protect your Personal Data, we cannot guarantee its absolute
                    security.</span><br /><br /><span style="color: #808080"><em><strong>Service
                        Providers</strong></em></span><br /><span style="color: #808080">We may employ third party
                    companies and individuals to facilitate our
                    Service ("Service Providers"), to provide the Service on our behalf,
                    to perform Service-related services or to assist us in analyzing how
                    our Service is used.</span><br /><span style="color: #808080">These third parties have access to your
                    Personal Data only to perform
                    these tasks on our behalf and are obligated not to disclose or use it
                    for any other purpose.</span><br /><br /><span style="color: #808080"><em><strong>Links To Other
                        Sites</strong></em></span><br /><span style="color: #808080">Our Service may contain links to
                    other sites that are not operated by
                    us. If you click on a third-party link, you will be directed to that
                    third party's site. We strongly advise you to review the Privacy
                    Policy of every site you visit.</span><br /><span style="color: #808080">We have no control over and
                    assume no responsibility for the content,
                    privacy policies, or practices of any third-party sites or
                    services.</span><br /><br /><span style="color: #808080"><em><strong>Children's
                        Privacy</strong></em></span><br /><span style="color: #808080">Our Service does not address anyone
                    under the age of 18
                    ("Children").</span><br /><span style="color: #808080">We do not knowingly collect personally
                    identifiable information from
                    anyone under the age of 18. If you are a parent or guardian and you
                    are aware that your Children have provided us with Personal Data,
                    please contact us. If we become aware that we have collected Personal
                    Data from children without verification of parental consent, we take
                    steps to remove that information from our servers.</span>
                </p>
                <p style="text-align: left">
                  As a parent, you are responsible for any and all account activity
                  conducted by a minor on your account. We will do our best to remediate
                  such violations as soon as we are notified of this infraction.&nbsp; We
                  will abide by local and international laws on this subject as it
                  pertains to the Privacy Act, the personal information of minors.<br /><br /><span
                    style="color: #808080"><em><strong>Changes To This Privacy Policy</strong></em></span><br /><span
                    style="color: #808080">We may update our Privacy Policy from time to time. We will notify
                    you of any changes by posting the new Privacy Policy on this
                    page.</span><br /><span style="color: #808080">We will let you know via email and/or a prominent
                    notice on our
                    Service, prior to the change becoming effective and update the
                    "effective date" at the top of this Privacy Policy.</span><br /><span style="color: #808080">You are
                    advised to review this Privacy Policy periodically for any
                    changes. Changes to this Privacy Policy are effective when they are
                    posted on this page. </span><span style="color: #808080">Contact Us</span><br /><span
                    style="color: #808080">If you have any questions about this Privacy Policy, please contact
                    us:</span><br /><br /><span style="color: #808080">By email: info@govava.com</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>

    </section>
  </div>
</template>
<script>
export default {
  name: "PrivacyView",
  data() {
    return {
      loader: false,
      errors: [],
      description: '',
      pagecontent: [],


    };
  },
  mounted() {
  },
  methods: {
  }
}
</script>